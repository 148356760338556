import React from 'react'
import { CookiesProvider } from 'react-cookie'
import { AppProvider } from '../context/app-context'

const RootElement = ({ children }) => (
  <AppProvider>
    <CookiesProvider>{children}</CookiesProvider>
  </AppProvider>
)

export default RootElement
