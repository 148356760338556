export const trackPageView = url => {
  if (typeof window._paq !== 'undefined') {
    window._paq.push(['setCustomUrl', url])
    window._paq.push(['trackPageView'])
  }
}

export const trackEvent = (category, action, name) => {
  if (typeof window._paq !== 'undefined') {
    window._paq.push(['trackEvent', category, action, name])
  }
}
