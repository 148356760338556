import './src/styles/global.css'

import React from 'react'
import RootElement from './src/components/root-element'

import { trackPageView } from './src/utils/piwik'

export const wrapRootElement = ({ element }) => <RootElement>{element}</RootElement>

export const onRouteUpdate = ({ location }) => {
  trackPageView(location.pathname)
}
